.loader {
  background: radial-gradient(
    circle,
    rgb(255, 255, 255) 0%,
    rgba(221, 221, 221, 1) 83%,
    rgba(207, 207, 207, 1) 100%
  );

  background: -webkit-radial-gradient(
    circle,
    rgb(255, 255, 255) 0%,
    rgba(221, 221, 221, 1) 83%,
    rgba(207, 207, 207, 1) 100%
  );

  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
  transition-timing-function: ease-in-out;
}

.loader img {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25vw;
  height: auto;

  @media screen and (max-width: 768px) {
    width: 50vw;
  }
}

.loading-percentage {
  color: var(--secondcolor);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5vw;
  border: 0.5px solid var(--secondcolor);
  padding: 3px;

  @media screen and (max-width: 768px) {
    font-size: 3vw;
  }
}

.loader.hidden {
  -webkit-animation: fadeOut 1.5s;
  animation: fadeOut 1.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  pointer-events: none;
}

@-webkit-keyframes fadeOut {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fadeOut {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.flicker-loader {
  -webkit-animation: flickerAnimation ease-in-out 3s infinite;
  animation: flickerAnimation ease-in-out 3s infinite;
}
