.hover-picture {
  display: none;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  /*  width: 100%;
  height: 100%; */
}

.hover-picture picture {
  //height: 100%;
  opacity: 1;
  width: 50%;
  // filter: blur(5px);
}
