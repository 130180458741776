.picture-blur {
  -webkit-filter: blur(10px) grayscale(100%);
  filter: blur(10px) grayscale(100%);
  transition: all 0.15s ease-in-out;
  opacity: 0;
}

.non-blurred {
  -webkit-filter: none;
  filter: none;
  transition: all 0.15s ease-in-out;
  opacity: 1;
}

.mobileblur {
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.blur {
  -webkit-filter: blur(20px);
  filter: blur(20px);
  transition-duration: 1s;
}

.mainColor {
  color: var(--maincolor);
  transition: var(--transition);

  &:hover {
    color: var(--secondcolor);
  }
}

/* .li_blur {
  -webkit-filter: blur(3px);
  filter: blur(3px);
  transition-duration: 1s;
  color: var(--maincolor);
} */

.pink-color {
  color: var(--maincolor);
}

.grey-color {
  color: var(--secondcolor);
}

.smaller {
  font-size: 5vw;
  color: lightgrey;
}

.color {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  transition: var(--transition);
}

/* @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdropblur {
    position: absolute;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdropblur {
    position: absolute;
    background-color: rgba(233, 233, 233, 0.429);
  }
} */

.backdropblur {
  position: absolute;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

/* @media screen and (max-width: 768px) {
  .li_blur {
    -webkit-filter: blur(1px);
    filter: blur(1px);
  }
}
 */
