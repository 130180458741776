.bio {
  font-size: 1.3vw;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: var(--textColor);

  padding: 1vw;
  border: 0.5px solid;
  //backdrop-filter: blur(25px);
  max-width: 100%;
  transition: var(--transition);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /*   &:hover {
    color: var(--maincolor);
  } */

  .linkColor {
    color: var(--textColor);
    text-decoration: underline;
    transition: var(--transition);

    &:hover {
      opacity: 0.7;
    }
  }

  @media screen and (max-width: 768px) {
    text-align: right;
    font-size: 3vw;
    left: unset;
    bottom: 0%;
    top: unset;
    //bottom: 5%;
    right: 0%;
    margin: 10px;
    transform: translate(0, 0);
    max-width: 65%;

    // padding: 2vw;
    //max-width: 100%;
  }
}
