/* photos
 */

#yonz,
#lara,
#joshua,
#pot,
#jonathan,
#danny,
#flowers,
#angelisation,
#warp,
#cecilia,
#skull {
  position: absolute;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
}

/* #panorama {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);
  display: none;
} */

#angelisation img,
#skull img {
  max-height: 75vh;
  max-width: 75vw;
}

#lara img,
#joshua img,
#flowers img,
#jonathan img {
  max-height: 25vw;
}

#yonz img,
#pot img {
  max-height: 30vw;
}

#danny img {
  max-height: 95vh;
}

/* videos
 */

.iframe-container {
  background-color: rgba(190, 190, 190, 0.401);
  position: relative;
  cursor: all-scroll;
  height: 45vh;
  width: 60vw;
  box-shadow: 2px -1px 34px 14px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 2px -1px 34px 14px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px -1px 34px 14px rgba(0, 0, 0, 0.05);
}

iframe {
  height: 45vh;
  width: 60vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-animation-name: img;
  animation-name: img;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

#warp iframe {
  width: 25vw;
}

#angelisation iframe,
#cecilia iframe {
  width: 45vw;
}
