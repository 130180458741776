#menu {
  margin-left: 10px;
  transition-duration: 1s;
  z-index: 1000;
  position: fixed;
  margin-top: 10px;
  padding: 0;
  margin-bottom: 0;
  top: 0;
  padding: 1vw;

  li {
    opacity: 0;
    font-size: 1.5vw;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

    padding: 2px;

    transition-duration: 1s;
    border: 0.5px solid transparent;

    a {
      color: var(--textColor);
      text-decoration: none;
      -webkit-filter: blur(0.5px);
      filter: blur(0.5px);
      text-decoration: none;
      transition: var(--transition);

      &:hover {
        transition-duration: 1s;
        -webkit-filter: blur(0px);
        filter: blur(0px);
        cursor: pointer;
        color: var(--textColor);
      }
    }

    &.li_selected {
      transition-duration: 1s;

      border: 0.5px solid var(--textColor);

      a {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        color: var(--textColor);
      }
    }

    @media screen and (max-width: 768px) {
      font-size: 4vw;
      /*    -webkit-filter: blur(1px);
      filter: blur(1px); */
    }
  }
}
