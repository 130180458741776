.website_url {
  color: var(--maincolor);
  cursor: pointer;

  &:hover {
    -webkit-filter: blur(2px);
    filter: blur(2px);
    transition: 0.5s;
  }
}

#contact .website_url {
  color: var(--maincolor);
}
