/* [data-scroll-container] {
  display: none;
  opacity: 0;
  z-index: 0;
  overflow: hidden;
  z-index: 0;
  position: relative;
} */

main {
  display: none;
}

section {
  opacity: 0;
  height: 100vh;
  display: flex;
  width: 100%;
  align-items: end;
  justify-content: end;
  z-index: 2;
  position: relative;

  img {
    max-width: 50vw;
    height: auto;
    max-height: 60vh;
    width: auto;
    // max-width: auto;
    transition: all 0.5s ease-in-out;
    border: 0.5px solid transparent;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    position: absolute;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &:hover {
      -webkit-filter: none;
      filter: none;
      border: 0.5px solid var(--textColor);
    }

    @media screen and (max-width: 768px) {
      &:last-child {
        transform: translate(-50%, -50%) rotate(-90deg);

        max-height: 75vw;
        max-width: 75vh;
        filter: none;
        -webkit-filter: none;
      }

      /*  &:not(last-child) {
        max-height: 60vh;
      
      } */
    }
  }

  // this is the DESCRIPTION
  .website-url {
    position: -webkit-sticky;
    position: sticky;

    font-size: 1.3vw;
    //color: var(--secondcolor);

    /*    width: auto;
    height: auto; */

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

    text-align: right;
    z-index: 999;

    padding: 5px;
    padding-bottom: 8px;
    bottom: 0%;
    right: 0%;
    margin: 10px;

    max-width: 50%;

    line-height: 1;

    /* backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(25px); */

    color: var(--textColor);
    border: 0.5px solid var(--textColor);

    p {
      font-size: 1.3vw;
      text-decoration: none;

      //transition: color 0.25s ease-in-out;

      transition: var(--transition);

      @media screen and (max-width: 768px) {
        font-size: 3vw;
      }
    }

    /*    &:hover {
      -webkit-filter: blur(1px);
      filter: blur(1px);
      transition-duration: 0.7s;
      cursor: pointer;
    } */

    a {
      font-size: 1.3vw;
      text-decoration: none;
      color: var(--textColor);
      //transition: color 0.25s ease-in-out;

      transition: var(--transition);

      @media screen and (max-width: 768px) {
        font-size: 3vw;
      }
    }

    @media screen and (max-width: 768px) {
      font-size: 3vw;
      max-width: 75%;
      /*     transform: rotate(-180deg);
      writing-mode: vertical-rl; */
    }
  }

  /* picture {
    position: absolute;
    width: auto;
    height: auto;
  } */

  /*  img {
    -webkit-animation-name: img;
    animation-name: img;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    transform: translate(0px, 0px);
    cursor: -webkit-grab;
    cursor: grab;
    max-height: 50vh;
    max-width: 75vw;
    width: auto;
    height: auto;
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
    border: 0.5px solid transparent;
    filter: grayscale(1); */

  /* &:hover {

    border: 0.5px solid var(--maincolor);
  } */
}
/* } */

/* @media screen and (max-width: 768px) {
  .info:not(.enter) {
    transform: rotate(-180deg);
    writing-mode: vertical-rl;
  }
}
 */
