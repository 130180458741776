/* .webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  z-index: 1;
  cursor: grab;
} */

/* canvas {
  position: fixed;
  bottom: 0;
  left: 0;
} */

/* canvas {
  position: absolute;
  bottom: 0;
} */

/* canvas {
  position: absolute;
  z-index: 1000;
} */

/* canvas {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
 */

canvas {
  // cursor: grab;
  z-index: 1;

  -webkit-animation: fadeIn 3s;
  animation: fadeIn 3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  pointer-events: none;

  background-color: var(--backgroundColor);

  transition: background-color 0.25s ease-in-out;
}
