@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300&display=swap");

:root {
  /*   --maincolor: #ff87c3;
  --maincolor: #48dd90; */
  --maincolor: #87ffc3;

  --maincolor: #e5e5e5;
  --maincolor: white;
  //--maincolor: #d5d5d5;

  --secondcolor: #ffffff;
  --pink: #ff87c3;
  --textColor: #ff87c3;
  --transition: all 0.25s ease-in-out;
  --backgroundColor: #ededed;
}

/* :root {
  scrollbar-color: blue none;
} */

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;

  margin: 0;
  padding: 0;
}

body,
html {
  height: 100%;
  width: 100%;
  font-family: "Inter", sans-serif;
  font-weight: 300;

  scroll-behavior: smooth;
  /*   background-color: var(--backgroundColor);

  transition: background-color 0.25s ease-in-out; */
  //background-attachment: fixed;
  /*  background: radial-gradient(
    circle,
    var(--maincolor) 0%,
    rgba(221, 221, 221, 1) 83%,
    rgba(207, 207, 207, 1) 100%
  );

  background: -webkit-radial-gradient(
    circle,
    var(--maincolor) 0%,
    rgba(221, 221, 221, 1) 83%,
    rgba(207, 207, 207, 1) 100%
  );


  transition: background 1s ease-in-out; */

  /*   overflow: hidden; */
  //cursor: crosshair;
  /*   scroll-behavior: smooth; */

  /*   overflow-y: hidden; */

  /*  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0) 0%,
    rgba(221, 221, 221, 1) 83%,
    rgba(207, 207, 207, 1) 100%
  );

  background: -webkit-radial-gradient(
    circle,
    rgba(255, 255, 255, 0) 0%,
    rgba(221, 221, 221, 1) 83%,
    rgba(207, 207, 207, 1) 100%
  );

  background-attachment: fixed; */

  /*   background: var(--maincolor);
  transition: background 0.25s ease-in-out; */

  //overflow-x: hidden;
  touch-action: manipulation;

  //scroll-snap-type: y mandatory;

  // overflow: overlay; // for the scrollbar
}

/* #background-color {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--backgroundColor);
  transition: background-color 0.25s ease-in-out;
} */

/* #background-gradient {
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle,
    transparent 0%,
    rgba(221, 221, 221, 1) 83%,
    rgba(207, 207, 207, 1) 100%
  );

  background: -webkit-radial-gradient(
    circle,
    transparent,
    rgba(221, 221, 221, 1) 83%,
    rgba(207, 207, 207, 1) 100%
  );

  background-attachment: fixed;
} */

/* #background-gradient {
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle,
    transparent 0%,
    rgba(221, 221, 221, 1) 95%,
    rgba(207, 207, 207, 1) 100%
  );

  background: -webkit-radial-gradient(
    circle,
    transparent 0%,
    rgba(221, 221, 221, 1) 95%,
    rgba(207, 207, 207, 1) 100%
  );

  background-attachment: fixed;
} */

/* #background-gradient {
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-color: #ff87c3;
  
  background-attachment: fixed;
}
 */

#background-color {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #0f16e6;
  // transition: background-color 0.25s ease-in-out;
}

::-moz-selection {
  background: transparent;
}

::selection {
  background: transparent;
}
::-moz-selection {
  background: transparent;
}

/////
html {
  scrollbar-width: thin; /* Makes the scrollbar thin */
  //scrollbar-color: var(--maincolor) rgba(207, 207, 207, 1); /* thumb color first, track color second */
  scrollbar-color: var(--textColor) var(--backgroundColor); /* thumb color first, track color second */
  transition: scrollbar-color 0.25s ease-in-out;
}

/* For WebKit browsers (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 5px; /* Adjust the width of the scrollbar */
  background: transparent;
  //background: var(--backgroundColor);
}

/* ::-webkit-scrollbar-track {
  background: transparent; 
} */

::-webkit-scrollbar-thumb {
  background: var(--textColor); /* Color of the scrollbar thumb */
  -webkit-transition: background 0.25s ease-in-out;
  transition: background 0.25s ease-in-out;
  border-radius: 10px; /* Optional: to make the thumb rounded */
}

/* ::-webkit-scrollbar-track {
  background-color: transparent;
}


::-webkit-scrollbar-thumb {
  background-color: transparent;
}


::-webkit-scrollbar-corner {
  background-color: transparent;
} */

/*  supports  */

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
}

/* mobile */

@media screen and (max-width: 768px) {
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
