.color-switcher {
  position: fixed;
  bottom: 0%;
  left: 0%;
  margin: 10px;
  font-size: 1.5vw;
  z-index: 2;

  transition: var(--transition);
  cursor: pointer;

  padding: 5px;

  color: var(--textColor);
  border: 0.5px solid transparent;

  opacity: 0;
  visibility: hidden;

  &:hover {
    //opacity: 0.5;
    //filter: blur(1px);
    border: 0.5px solid var(--textColor);
  }

  @media screen and (max-width: 768px) {
    font-size: 3vw;

    &:hover {
      -webkit-filter: none;
      filter: none;
    }
  }
}
