.enter {
  position: fixed;
  font-size: 1.5vw;
  color: var(--textColor);
  text-align: center;
  z-index: 999;
  padding: 3px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  transition: var(--transition);

  /*   backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px); */

  -webkit-filter: blur(0.5px);
  filter: blur(0.5px);
  cursor: pointer;

  &:hover {
    /*   -webkit-filter: blur(2px);
    filter: blur(2px); */
    -webkit-filter: none;
    filter: none;
    border: 0.5px solid var(--textColor);
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    font-size: 4vw;
  }
}

/* #close {
  position: fixed;
  top: 1%;
  right: 1%;
  font-size: 3vw;
  color: var(--secondcolor);
  z-index: 2000;
  //line-height: 1;
  //   transform: rotate(-180deg);
 //-webkit-writing-mode: vertical-rl;
 // -ms-writing-mode: tb-rl;
 // writing-mode: vertical-rl; 
  display: none;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  padding-right: 0.4vw;
  padding-left: 0.4vw;
  border: 0.5px solid var(--maincolor);

  @media screen and (max-width: 768px) {
    font-size: 7vw;
    right: 2%;
  }

  &:hover {
    -webkit-filter: blur(2px);
    filter: blur(2px);
    transition-duration: 0.7s;
    cursor: pointer;
  }
}
 */

/* .button_bottomleft {
  position: fixed;
  bottom: 2%;
  left: 1%;
  z-index: 1000;

  a {
    text-decoration: none;
    color: var(--textColor);
    font-size: 4vw;
    -webkit-filter: blur(1px);
    filter: blur(1px);
    transition: all 0.5s ease-in-out;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;

    @media screen and (max-width: 768px) {
      font-size: 8vw;
    }

    &:hover {
      -webkit-filter: none;
      filter: none;
    }
  }
} */
