/* animations */

@-webkit-keyframes img {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes img {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeout {
  -webkit-animation: fadeOut2 0.25s;
  animation: fadeOut2 0.25s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes fadeOut2 {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fadeOut2 {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
